import * as actionTypes from '../actionTypes';
import {updateState} from '../utilities';

const initialState = {
	theme: !!localStorage.getItem('theme') ? localStorage.getItem('theme') : 'default',
	loading: false,
	messageType: null,
	messageContent: null,
	language: !!localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en',
	ubotToastType: null,
	ubotToastContent: null,
};

const setTheme = (state, action) => {
	return updateState(
		state,
		{
			theme: action.theme,
		}
	)
};

const setMessage = (state, action) => {
	return updateState(
		state,
		{
			messageContent: action.messageContent,
			messageType: action.messageType
		}
	)
};

const setLoading = (state, action) => {
	return updateState(
		state,
		{
			loading: action.loading
		}
	)
};

const setLanguage = (state, action) => {
	return updateState(
		state,
		{
			language: action.language
		}
	)
};

const setUbotToast = (state, action) => {
	return updateState(
		state,
		{
			ubotToastType: action.ubotToastType,
			ubotToastContent: action.ubotToastContent
		}
	)
};

const reducer = (state=initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_THEME: return setTheme(state, action);
		case actionTypes.SET_MESSAGE: return setMessage(state, action);
		case actionTypes.SET_LOADING: return setLoading(state, action);
		case actionTypes.SET_LANGUAGE: return setLanguage(state, action);
		case actionTypes.SET_UBOT_TOAST: return setUbotToast(state, action);
		default: return state;
	}
};

export default reducer;